.product-header-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 35px;
    width: 100%;
}

.product-header-container .title {
    font-size: 48px;
    padding: 35px 0 13px 0;
    font-weight: lighter;
}

.product-header-container .breadcrumb .menu {
    text-decoration: none !important;
    color: #666666;
}
