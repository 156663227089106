.content-four-container {
    /*height: 750px;*/
}

.content-four-container .header {
    padding: 50px;
    background-color: var(--navy-100);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content-four-container .header .left-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
}

.content-four-container .header .left-section .title {
    font-size: 30px;
    color: white;
    font-weight: bold;
}

.content-four-container .header .left-section .divider {
    background-color: #eeeeee2b;
    width: 1px;
    height: 50px;
    margin: 0 20px;
}

.content-four-container .header .left-section .description {
    font-size: 14px;
    color: #bbbbbb;
    margin-right: 20px;
}

.content-four-container .header .right-section {}

.content-four-container .header .right-section .btn-container {
    display: flex;
    flex: 0.5;
}

.content-four-container .header .right-section .btn-container .btn {
    height: 50px !important;
    width: 50px !important;
    color: var(--orange-100);
}

/* Swiper */
.content-four-container .swiper {
    /*height: 80%;*/
}

.content-four-container .swiper .image {
    position: relative;
    border: 1px solid var(--navy-100);
    height: 500px;
    z-index: 1;
}

.content-four-container .swiper .image .title {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(10, 10, 10, 0.71);
    width: 310px;
    height: 100px;
    font-size: 30px;
    border-bottom-right-radius: 10px;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    color: white;
    font-weight: bold;
    z-index: 10;
}

.content-four-container .swiper .image .title.visible {
    color: var(--orange-100);
}

.content-four-container .swiper .image .description {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(10, 10, 10, 0.8);
    z-index: 2;
    /*animation: slide-in 0.5s forwards;*/
    opacity: 0; /* 처음에는 투명하게 */
    transition: all 0.5s ease;
    padding: 150px 100px 60px;
}

.content-four-container .swiper .image .description.visible {
    left: 0; /* 화면 안으로 위치 이동 */
    opacity: 1; /* 불투명하게 변경 */
}

.content-four-container .swiper .image .description .wrapper {
    border: 4px solid #cccccc;
    height: 100%;
    padding: 20px;
    color: white;
}

@media screen and (max-width: 600px) {
    .content-four-container .header {
        flex-direction: column;
        padding: 40px 20px;
        align-items: flex-start;
        row-gap: 20px;
    }

    .content-four-container .header .left-section {
        flex-direction: column;
        align-items: flex-start;
    }

    .content-four-container .header .left-section .title {
        margin-bottom: 10px;
    }

    .content-four-container .header .left-section .divider {
        display: none;
    }

    .content-four-container .header .right-section {
        display: none;
    }
}
