.contact-container {
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    /*justify-content: center;*/
    padding: 70px 300px;
    background-color: #f1f1f1;
}

.contact-container .content-wrapper {
    display: flex;
    height: 100%;
    column-gap: 40px;
}

.contact-container .content-wrapper .left {
    width: 50%;
    height: 100%;
}

.contact-container .content-wrapper .left .item-container {
    display: flex;
    flex-direction: column;
    /*height: 100%;*/
    /*justify-content: space-around;*/
    row-gap: 30px;
}

.contact-container .content-wrapper .left .item-container .item-wrapper {
    display: flex;
    align-items: center;
    padding: 30px 20px;
    /*height: 100%;*/
}

.contact-container .content-wrapper .left .item-container .item-wrapper .icon-container {
    display: flex;
    align-items: center;
    margin-right: 25px;
}

.contact-container .content-wrapper .left .item-container .item-wrapper .icon-container .text {
    font-size: 14px;
}

.contact-container .content-wrapper .left .item-container .item-wrapper .icon-container .icon-wrapper {
    border: 2px solid #ddd; /* 테두리 설정 */
    border-radius: 50%; /* 동그라미 모양으로 만들기 */
    background-color: white; /* 배경색 설정 */
    transition: all 0.5s ease;
    width: 60px; /* 너비 설정 */
    height: 60px; /* 높이 설정 */
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-container .content-wrapper .left .item-container .item-wrapper .icon-container .icon-wrapper .icon {
    color: #aaaaaa;
    height: 50%;
    width: 50%;
}

.contact-container .content-wrapper .left .header {
    /*font-size: 20px;*/
    /*font-weight: bold;*/
    font-size: 48px;
    font-weight: lighter;
    margin-bottom: 10px;
}

.contact-container .content-wrapper .left .description {
    font-size: 14px;
    color: #5e5e5e;
    margin-bottom: 20px;
}

.contact-container .content-wrapper .right {
    width: 50%;
    height: 100%;
}
.contact-container .content-wrapper .right .map {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 600px) {
    .contact-container {
        height: calc(100% + 70px);
        margin-top: -70px !important;
        padding: 80px 20px !important;
    }

    .contact-container .content-wrapper {
        flex-direction: column;
    }

    .contact-container .content-wrapper .left {
        width: 100%;
        margin-bottom: 30px;
    }

    .contact-container .content-wrapper .right {
        width: 100%;
    }
}
