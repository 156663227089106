.product-container {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*padding: 20px 200px;*/
    /*height: 100vh;*/
    /*height: 100%;*/
}

.product-container .top-wrapper {
    display: flex;
    column-gap: 30px;
    /*height: 50%;*/
    /*height: 100%;*/
    height: calc(100vh - 70px);
    padding: 20px 300px;
    justify-content: center;
}

.product-container .top-wrapper .left {
    width: 560px;
    height: 100%;

}

.product-container .top-wrapper .left .mySwiper3 {
    height: 70%;
    border: 1px solid rgba(0, 0, 0, 0.12);
    /*width: 600px;*/
}

.product-container .top-wrapper .left .mySwiper3 .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.product-container .top-wrapper .left .mySwiper2 {
    height: 30%;
    margin: 10px 0;
}

.product-container .top-wrapper .left .mySwiper2 .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
    cursor: pointer;
    transition: opacity 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.product-container .top-wrapper .left .mySwiper2 .swiper-slide:hover {
    opacity: 0.7;
}

.product-container .top-wrapper .left .mySwiper2 .swiper-slide-thumb-active {
    opacity: 1 !important;
}

.product-container .top-wrapper .left .mySwiper2 .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.product-container .top-wrapper .right {
    width: 40%;
    height: 100%;
}

.product-container .top-wrapper .right .Mui-selected {
    color: var(--orange-100);
}

.product-container .top-wrapper .right .title {
    font-size: 32px;
    font-weight: bold;
    padding: 20px 0;
}

.product-container .top-wrapper .right .text {
    margin-bottom: 10px;
}

.product-container .top-wrapper .right .content-container {
    padding: 20px 0;
}

.product-container .top-wrapper .right .size-wrapper {
    display: flex;
    align-items: center;
    column-gap: 6px;
    height: 50px;
}

.product-container .top-wrapper .right .size-wrapper .size {
    border: 1px solid #aaaaaa;
    height: 100%;
    flex: 1;
    align-content: center;
    text-align: center;
    font-size: 12px;
    color: #666666;
}

.product-container .top-wrapper .right .description {
    font-size: 14px;
    color: #666666;
    padding: 10px;
}

.product-container .bottom-wrapper {
    width: 100%;
}

@media screen and (max-width: 600px) {
    .product-container .top-wrapper {
        flex-direction: column;
        padding: 0 20px;
        height: 100%;
    }

    .product-container .top-wrapper .left {
        width: 100%;
    }

    .product-container .top-wrapper .right {
        width: 100%;
    }

}
