.content-two-container {
    /*height: 500px;*/
    background-color: var(--navy-100);
    display: flex;
    align-items: stretch;
    padding: 50px 200px;
    column-gap: 40px;
}

.content-two-container .card {
    flex: 1;
    background-color: var(--navy-100);
    border: 10px solid white;
    border-radius: 0;

}

.content-two-container .card .number {
    background-color: var(--orange-100);
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    color: white;
    border-bottom-right-radius: 10px;
}

.content-two-container .card .text-container {
    padding: 30px 50px 60px;
}

.content-two-container .card .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 18px;
    color: white;
    transition: all 0.5s ease;
}

.content-two-container .card .description {
    font-size: 15px;
    color: #cccccc;
    transition: all 0.5s ease;
}

.content-two-container .card:hover .title {
    color: var(--orange-100);
}

.content-two-container .card:hover .description {
    color: white;
}

@media screen and (max-width: 600px) {
    .content-two-container {
        padding: 20px;
        flex-direction: column;
        row-gap: 34px;
    }
}
