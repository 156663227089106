.product-list-container {
    display: flex;
    row-gap: 20px;
    column-gap: 20px;
    padding-top: 70px;
    width: 100%;
    /*flex-grow: 1;*/
    /*flex-wrap: wrap;*/
}

.product-list-container .card {
    min-width: 400px;
    max-width: 400px;
    width: 100%;
    /*flex: 1 25%;*/
}
