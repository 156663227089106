.content-five-container {
    width: 100%;
    position: relative;
}

.content-five-container .wrapper {
    display: flex;
    flex-direction: column;
    padding: 50px;
}

.content-five-container .shape {
    position: absolute;
    top: 0;
    left: 0;
    height: 64%;
    width: 100%;
    background-color: var(--navy-100);
    z-index: 0;
}

.content-five-container .header {
    font-size: 50px;
    font-weight: bold;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    z-index: 1;
}

.content-five-container .info-container {
    display: flex;
    column-gap: 20px;
    z-index: 1;
    align-items: stretch;
}

.content-five-container .info-container .info {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0;
    transition: all 0.5s ease;
    border-top: 4px solid transparent;
}

.content-five-container .info-container .info:hover {
    border-top: 4px solid var(--orange-100);
}

.content-five-container .info-container .info .icon-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    row-gap: 10px;
    padding: 20px 0;
}

.content-five-container .info-container .info .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px; /* 너비 설정 */
    height: 90px; /* 높이 설정 */
    border: 2px solid #ddd; /* 테두리 설정 */
    border-radius: 50%; /* 동그라미 모양으로 만들기 */
    background-color: white; /* 배경색 설정 */
    transition: all 0.5s ease;
}

.content-five-container .info-container .info:hover .icon-wrapper {
    border: 2px solid var(--orange-100);
}

.content-five-container .info-container .info .icon-wrapper .icon {
    color: #aaaaaa;
    height: 50%;
    width: 50%;
}

.content-five-container .info-container .info:hover .icon-wrapper .icon {
    color: var(--orange-100);
}

.content-five-container .info-container .info .title {
    font-size: 20px;
    font-weight: bold;
    color: #b0b0b0;
    text-align: center;
    transition: all 0.5s ease;
    flex: 1;
}

.content-five-container .info-container .info:hover .title {
    color: var(--orange-100);
}

.content-five-container .info-container .info .text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
    font-size: 14px;
    text-align: center;
    color: #565656;
    transition: all 0.5s ease;
    flex: 1;
}

.content-five-container .info-container:hover .info .text {
    /*font-size: 16px;*/
}

@media screen and (max-width: 600px) {
    .content-five-container .wrapper {
        padding: 30px 20px;
    }

    .content-five-container .header {
        font-size: 36px;
    }

    .content-five-container .info-container {
        flex-direction: column;
        row-gap: 40px;
    }
}
