.mainFooter-container {
    width: 100%;
    position: relative;
}

.mainFooter-container .footer-image {
    width: 100%;
    height: 100%;
    background-image: url("../../../../assets/images/factory.jpg");
    background-size: cover !important; /* 이미지를 컨테이너에 가득 차게 확대 */
    background-position: center !important; /* 이미지를 가운데 정렬 */
    background-repeat: no-repeat !important;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
}

.mainFooter-container .footer-image .footer-image-cover {
    width: 100%;
    height: 100%;
    background-color: #2a2a2a;
    opacity: 0.7;
}

.mainFooter-container .contents {
    width: 100%;
    display: flex;
    text-align: left;
    padding: 50px;
    column-gap: 70px;
}

.mainFooter-container .contents .section {
    flex: 1;
}

/* Section1 */
.mainFooter-container .contents .section.section1 .logo {
    height: 80px;
}

.mainFooter-container .contents .section.section1 .text {
    color: white;
    white-space: break-spaces;
    margin-top: 14px;
    font-size: 14px;
}

/* Section2 */
.mainFooter-container .contents .section.section2 {
    flex: 1;
}

.mainFooter-container .contents .section.section2 .title {
    color: white;
    font-weight: bold;
    margin-bottom: 14px;
}

.mainFooter-container .contents .section.section2 .content {
    display: flex;
    color: white;
    padding: 10px 0;
    font-size: 14px;
}

.mainFooter-container .contents .section.section2 .content .text {
    flex: 1;
}

.mainFooter-container .contents .section.section2 .content .date {
    color: #cccccc;
}

.mainFooter-container .contents .section.section2 .content .time {
    text-align: right;
    font-weight: bold;
}

/* Section3 */
.mainFooter-container .contents .section.section3 {
    flex: 1;
}

.mainFooter-container .contents .section.section3 .title {
    color: white;
    font-weight: bold;
    margin-bottom: 14px;
}

.mainFooter-container .contents .section.section3 .content {
    display: flex;
    color: white;
    font-size: 14px;
    align-items: center;
}

.mainFooter-container .contents .section.section3 .content .text {
    flex: 1;
}

.mainFooter-container .contents .section.section3 .content .time {
    text-align: left;
    margin-left: 10px;
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .mainFooter-container {}

    .mainFooter-container .contents {
        display: flex;
        flex-direction: column;
        padding: 30px 20px;
        row-gap: 40px;
    }
}
