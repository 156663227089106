.content-one-container {
    position: relative;
}

/*.content-one-container::-webkit-scrollbar {*/
/*    display: none;*/
/*}*/


.content-one-container .mySwiper {
    /*height: calc(100vh - 64px);*/
    height: 100vh;
}

.content-one-container .mySwiper .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* detail-container */
.content-one-container .detail-container {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
    background-color: hsla(0, 0%, 5%, 0.81);
    width: 480px;
    height: 460px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.content-one-container .detail-container .theme {
    font-size: 15px;
    font-weight: bold;
    color: white;
    margin-bottom: 16px;
}

.content-one-container .detail-container .title {
    font-size: 36px;
    font-weight: bold;
    color: white;
    margin-bottom: 16px;
}

.content-one-container .detail-container .description {
    font-size: 14px;
    color: #bbbbbb;
    line-height: 1.7;
}

/* btn-container */
.content-one-container .btn-container {
    position: absolute;
    top: 97%;
    right: 370px;
    transform: translateY(-50%);
    z-index: 2;
    display: flex;
    flex-direction: row;
}

.content-one-container .btn-container .btn {
    height: 60px;
    width: 60px;
    background-color: var(--orange-100);
}

.content-one-container .btn-container .prev-btn {
    margin-right: 8px;
}

@media screen and (max-width: 600px) {
    /* detail-container */
    .content-one-container .detail-container {
        top: 80%;
        width: 360px;
        height: 100px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .content-one-container .detail-container .theme {
        display: none;
    }

    .content-one-container .detail-container .title {
        font-size: 32px;
        margin-bottom: 0;
    }

    .content-one-container .detail-container .description {
        display: none;
    }

    /* btn-container */
    .content-one-container .btn-container {
        display: none;
    }
}
