.subFooter-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--navy-100);
    padding: 0 50px;
}

.subFooter-container .copyright {
    color: #999999;
    font-size: 14px;
}

.subFooter-container .to-top-icon-container {
    display: flex;
    flex-direction: column;
}

.subFooter-container .to-top-icon-container .to-top-icon .text {
    color: #999999;
    font-size: 11px;
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .subFooter-container {
        padding: 0 20px;
    }

    .subFooter-container .copyright {
        font-size: 8px;
    }

    .subFooter-container .to-top-icon-container .to-top-icon .icon {
        height: 12px;
        width: 12px;
    }

    .subFooter-container .to-top-icon-container .to-top-icon .text {
        font-size: 8px;
    }
}
