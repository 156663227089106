.products-container {
    height: calc(100vh - 70px);
    background-color: #efefef;
    padding: 70px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.products-container .header {
    font-size: 50px;
    font-weight: bold;
}

.products-container .description {
    font-size: 28px;
    color: #8a8a8a;
    margin-top: 10px;
    margin-bottom: 40px;
}

.products-container .category-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    column-gap: 30px;
}

.products-container .category-container .category {
    flex: 1;
    height: 250px;
    border: 4px solid transparent;
    transition: all 0.5s ease;
    color: black;
    cursor: pointer;
    text-decoration: none;
}

.products-container .category-container .category:hover {
    border: 4px solid var(--orange-100);
    color: var(--orange-100);
}

.products-container .category-container .category .category-action {
    height: 100%;
    width: 100%;
    padding: 50px;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .products-container {
        height: calc(100% + 70px);
        margin-top: -70px !important;
        padding: 80px 20px !important;

    }

    .products-container .header {
        font-size: 36px;
    }

    .products-container .description {
        font-size: 18px;
    }

    .products-container .category-container {
        flex-direction: column;
        row-gap: 30px;
        align-items: stretch;
    }

    .products-container .category-container .category {

    }
}
