.main-header {
    width: 100%;
}

.main-header .container {
    padding: 0 50px;

}

.main-header .container .toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.main-header .container .left-section {
    display: flex;
    align-items: center;
}

.main-header .logo {
    height: 70px;
    padding: 10px 0;
}

.main-header .button {
    color: black;
    font-weight: bold;
    font-size: 14px;
}

.main-header .language .button {
    font-size: 12px;
}

.main-header .language .menu .item {
    font-size: 4px;
    width: 100%;
}

.main-header .button:hover {
    color: var(--orange-100);
}

@media screen and (max-width: 600px) {
    .mobile-menu-container {
        position: absolute;
        top: 20px;
        left: 10px;
        width: 100px;
        height: 100px;
        z-index: 2;
    }

    .mobile-menu-container .menu-btn {
        border: 1px solid var(--orange-100);
        opacity: 0.8;
    }

    .mobile-menu-container .drawer .side-menu-container {
        width: 20px;
        background-color: red;
    }

    .main-header .container {
        padding: 0 20px;
    }

    .main-header .container .menu-container {
        display: none;
    }

    .main-header .logo {
        height: 60px;
        padding: 10px 0;
    }
}
