.nylon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #efefef;
    justify-content: center;
    width: 100%;
    padding: 70px 400px;
}

@media screen and (max-width: 600px) {
    .nylon-container {
        margin-top: -70px !important;
        padding: 70px 20px;
    }
}
