.content-three-container {
    padding: 150px 150px;
    display: flex;
    column-gap: 150px;
}

/* Left-Section */
.content-three-container .left-section {
    flex: 1;
}

.content-three-container .left-section .title {
    font-size: 50px;
}

.content-three-container .left-section .divider {
    width: 140px;
    height: 3px;
    background-color: var(--orange-100);
    margin: 16px 0;
}

.content-three-container .left-section .description {
    color: #666666;
    font-size: 16px;
    line-height: 22px;
}

/* Right-Section */
.content-three-container .right-section {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.content-three-container .right-section .title {
    font-size: 20px;
}

.content-three-container .right-section .count-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 16px 0;
}

.content-three-container .right-section .count-container .count {
    font-size: 80px;
    font-weight: bold;
}

.content-three-container .right-section .count-container .plus {
    font-size: 60px;
    font-weight: bold;
    color: var(--orange-100);
    margin-left: 15px;
}

.content-three-container .right-section .description {
    font-size: 16px;
    color: #666666;
    line-height: 22px;
}

@media screen and (max-width: 600px) {
    .content-three-container {
        flex-direction: column;
        padding: 50px 20px !important;
    }

    /* Right-Section */
    .content-three-container .right-section {
        margin-top: 70px;
    }

    .content-three-container .right-section .count-container .count {
        font-size: 60px;
        font-weight: bold;
    }

    .content-three-container .right-section .count-container .plus {
        font-size: 40px;
    }
}
