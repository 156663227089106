body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.light {
  background-color: #FFF;
}

body.dark {
  background-color: #1e1e1e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}
*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  overflow:auto;
  border-radius: 4px;
}
*::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.2);
}

.scroll-w {
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}
.scroll-w::-webkit-scrollbar {
  display: none;
}

.scroll-w5::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}


:root {
  width: 100%;
  height: 100%;

  --main-background-color: #1D262D;

  --blue-100: #DCEBFE;
  --blue-110: #8DA4BF;
  --blue-120: #6F9FBA;
  --blue-130: #117CE9;

  --gray-100: #CFD5E2;
  --gray-110: #C5C5C5;
  --gray-120: #B1C4D7;
  --gray-130: #B9B9B9;
  --gray-140: #A6ACBE;

  --green-100: #63D475;

  --red-100: #F33B12;

  --orange-100: #ed6c02;

  --navy-100: #081225;
}

.ta-center {
  text-align: left;
}

.gm-style-iw-d {
  color: black;
}


/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/
