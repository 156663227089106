.base-wrapper-root {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
}

.base-wrapper-content {
    flex: 1;
    transition: background-color 0.5s ease;
    position: relative;
    margin-top: 70px;
}

.base-wrapper-root::-webkit-scrollbar {
    display: none;
}
